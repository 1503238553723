import FormStep, {
    MembershipWizardForm
} from 'web/screen/MembershipApplication/FormStep/FormStep';
import { computed, observable } from 'mobx';
import {
    Form,
    FormField,
    NullValidator,
    RequiredFieldValidator
} from 'react-mvvm/forms';
import BirthDatePicker from 'common/components/BirthDatePicker/BirthDatePicker';
import { MembershipApplicationDto } from 'model/Api/Members/Model/MembershipApplicationDto';

class AssociateForm implements MembershipWizardForm {
    parent: FormStep;
    @observable form: Form<any>;
    @observable formValues: Partial<MembershipApplicationDto> = {};

    constructor(parent: FormStep) {
        this.parent = parent;
        this.form = this.buildMembershipForm();
    }

    @computed get userAddresses() {
        return this.parent.userAddresses;
    }

    @computed get application() {
        return this.parent.application;
    }

    buildMembershipForm = () => {
        return new Form({
            birthDate: new BirthDatePicker(
                this.parent.parent.initApplication?.birthDate,
                RequiredFieldValidator
            ),
            gender: new FormField(
                this.parent.parent.initApplication?.gender,
                RequiredFieldValidator
            ),
            privatePartyId: new FormField(
                this.parent.parent.initApplication?.privatePartyId,
                RequiredFieldValidator
            ),
            employerPartyId: new FormField(
                this.parent.parent.initApplication?.employerPartyId,
                NullValidator
            ),
            workPosition: new FormField(
                this.parent.parent.initApplication?.workPosition,
                RequiredFieldValidator
            ),
            acceptPrivacyPolicy: new FormField(
                this.application.acceptPrivacyPolicy,
                RequiredFieldValidator
            )
        });
    };

    onSaveForm = async (): Promise<boolean> => {
        const validation = await this.form.validate();
        if (validation) {
            const { fields } = this.form;
            this.formValues = {
                ...this.formValues,
                birthDate: fields.birthDate.value,
                gender: fields.gender.value,
                privatePartyId: fields.privatePartyId.value,
                employerPartyId: fields.employerPartyId.value,
                workPosition: fields.workPosition.value,
                acceptPrivacyPolicy: fields.acceptPrivacyPolicy.value
            };
            return true;
        }
        return false;
    };

    onNext = async () => {
        return this.onSaveForm();
    };

    @computed get isNextDisable() {
        return (
            this.form.isPristine &&
            !this.userAddresses?.doubleSelectedParty.private &&
            !this.userAddresses?.doubleSelectedParty.company
        );
    }

    onSelectAddress = async () => {
        if (!!this.userAddresses) {
            const { doubleSelectedParty } = this.userAddresses;
            this.form.fields.privatePartyId.value = doubleSelectedParty.private;
            this.form.fields.employerPartyId.value =
                doubleSelectedParty.company;
        }
    };
}

export default AssociateForm;
