import * as React from 'react';
import { FormField } from 'react-mvvm/forms';
import AntField from 'common/components/antHelpers/AntField';
import { Checkbox, Input } from 'antd';
import style from './ProfessionalForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

interface MembershipApplicationProfessionalGroupsViewProps {
    field: FormField<string[] | undefined>;
    showLabel?: boolean;
    disabled?: boolean;
}

const options: string[] = [
    'Grafill Grafisk Design',
    'Grafill Interaktiv/digital design',
    'Grafill Illustrasjon',
    'Grafill Tegneserie',
    'Grafill Moving Image'
];

const otherOption = 'Annet';

const MembershipApplicationProfessionalGroupsView: React.FC<MembershipApplicationProfessionalGroupsViewProps> = ({
    field,
    showLabel = true,
    disabled
}) => {
    const { t } = useTranslation('membership');
    const [checkboxGroupValue, setCheckboxGroupValue] = React.useState<
        string[] | undefined
    >(field.value);
    const [customValue, setCustomValue] = useState('');

    const handleCheckboxGroupChange = (
        value: CheckboxValueType[],
        onChange: (value: string[] | undefined) => void
    ) => {
        setCheckboxGroupValue(value as any);

        const customizedValue = [...value];
        const index = customizedValue.indexOf(otherOption);
        if (index !== -1) {
            customizedValue[index] = !!customValue ? customValue : otherOption;
        }
        onChange(customizedValue as any);
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        onChange: (value: string[] | undefined) => void
    ) => {
        setCustomValue(e.target.value);

        const customizedValue = !!checkboxGroupValue
            ? [...checkboxGroupValue]
            : [];
        const index = customizedValue.indexOf(otherOption);
        if (index !== -1) {
            customizedValue[index] = !!customValue ? customValue : otherOption;
        }
        onChange(customizedValue as any);
    };

    return (
        <AntField
            field={field}
            label={showLabel ? t('professionalGroupsLabel') : ''}>
            {(props) => {
                return (
                    <Checkbox.Group
                        disabled={disabled}
                        value={
                            !!checkboxGroupValue
                                ? checkboxGroupValue.map((v) => v)
                                : undefined
                        }
                        onChange={(e) => {
                            handleCheckboxGroupChange(e, props.onChange);
                        }}>
                        {options.map((item, index) => {
                            return (
                                <Checkbox
                                    disabled={disabled}
                                    value={item}
                                    key={`intrests-checkbox__${index}`}
                                    className={style.checkbox}>
                                    {item}
                                </Checkbox>
                            );
                        })}
                        <Checkbox
                            disabled={disabled}
                            value={otherOption}
                            key={`intrests-checkbox__${otherOption}`}
                            className={style.checkbox}>
                            {otherOption}
                        </Checkbox>
                        {checkboxGroupValue &&
                            checkboxGroupValue.includes(otherOption) && (
                                <Input
                                    value={customValue}
                                    onChange={(e) =>
                                        handleInputChange(e, props.onChange)
                                    }
                                />
                            )}
                    </Checkbox.Group>
                );
            }}
        </AntField>
    );
};

export default MembershipApplicationProfessionalGroupsView;
