import * as React from 'react';
import AntForm from 'common/components/antHelpers/AntForm';
import moment, { Moment } from 'moment';
import AntField from 'common/components/antHelpers/AntField';
import { FieldInputProps } from 'common/components/antHelpers/AntField/AntField';
import AntDatePicker from 'common/components/antHelpers/AntDatePicker';
import FieldPolicy from '../FieldPolicy';
import GenderField from 'common/components/antHelpers/GenderField/GenderField';
import { useTranslation } from 'react-i18next';
import SingleUserAddressesDialogsView from 'common/components/UserAddresses/SingleUserAddressesDialogsView';
import SingleUsersAddressDropdown from 'common/components/SignleUserAddressDropdown/SingleUsersAddressDropdown';
import BirthDatePicker from 'common/components/BirthDatePicker/BirthDatePicker';
import BirthDatePickerView from 'common/components/BirthDatePicker/BirthDatePickerView';
import style from './GraduateForm.module.scss';
import { Form } from 'react-mvvm/forms';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import Link from 'web/components/Link';
import AntCheckbox from 'common/components/antHelpers/AntCheckbox';
import FileUploadView from 'common/components/antHelpers/FileUpload/FileUploadView';
import FileUpload from 'common/components/antHelpers/FileUpload/FileUpload';
import UrlFieldView from 'common/components/antHelpers/UrlField/UrlFieldView';
import UrlField from 'common/components/antHelpers/UrlField/UrlFied';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import MembershipApplicationProfessionalGroupsView from '../ProfessionalForm/MembershipApplicationProfessionalGroupsView';
import MembershipRequirementField from 'common/components/antHelpers/MembershipRequirementField';

interface GraduateFormViewProps {
    form: Form<any>;
    userAddresses: UserAddresses | undefined;
    membershipAppId: number;
    isEditView?: boolean;
}

const GraduateFormView: React.FC<GraduateFormViewProps> = ({
    form,
    userAddresses,
    membershipAppId,
    isEditView = false
}) => {
    const { t } = useTranslation('membership');
    return (
        <AntForm form={form} layout={'vertical'}>
            {(fields) => {
                return (
                    <>
                        <div className={style.row}>
                            <BirthDatePickerView
                                model={fields.birthDate as BirthDatePicker}
                            />
                        </div>
                        {!!fields.gender && (
                            <GenderField field={fields.gender} />
                        )}
                        <AntField
                            field={fields.privatePartyId}
                            label={t('selectPrivateAddress')}>
                            {(props) => {
                                return (
                                    <SingleUsersAddressDropdown
                                        model={userAddresses}
                                        type={'private'}
                                    />
                                );
                            }}
                        </AntField>
                        <AntField
                            field={fields.employerPartyId}
                            label={t('selectCompanyAddress')}>
                            {(props) => {
                                return (
                                    <SingleUsersAddressDropdown
                                        model={userAddresses}
                                        type={'company'}
                                    />
                                );
                            }}
                        </AntField>
                        {!!fields.workPosition && (
                            <AntField
                                field={fields.workPosition}
                                label={t('workPosition')}
                                description={
                                    'Dersom du ikke har en offisiell stillingstittel, oppgi en beskrivelse av hva du arbeider\n' +
                                    'med, f.eks illustratør, grafisk designer, e.l.'
                                }>
                                {(props) => <AntTextInput {...props} />}
                            </AntField>
                        )}
                        <MembershipRequirementField
                            field={fields.membershipApplicationRequirement}
                        />
                        {!!fields.expectedGraduationDate && (
                            <AntField
                                field={fields.expectedGraduationDate}
                                label={t('graduationDate')}>
                                {(
                                    props: FieldInputProps<Moment | undefined>
                                ) => (
                                    <AntDatePicker
                                        {...props}
                                        disabledDate={(current) =>
                                            current &&
                                            (current <
                                                moment(new Date()).add(
                                                    'years',
                                                    -2
                                                ) ||
                                                current > moment(new Date()))
                                        }
                                    />
                                )}
                            </AntField>
                        )}
                        {!!fields.experienceConfirmation && !isEditView && (
                            <AntField
                                className={style.noBorderRow}
                                field={fields.experienceConfirmation}
                                description={
                                    'For å bli medlem må du ha minimum bachelorgrad innen relevante fag, eller minimum tre\n' +
                                    'års relevant arbeidserfaring. Dette kan du dokumentere med filer eller lenker til relevant nettsider.'
                                }
                                dscextend={
                                    <span>
                                        Se{' '}
                                        <Link
                                            href={
                                                'https://www.grafill.no/medlem/sok-medlemskap'
                                            }>
                                            her
                                        </Link>{' '}
                                        for mer informasjon.
                                    </span>
                                }
                                label={
                                    'Relevant utdannelse og arbeidserfaring'
                                }>
                                {(props) => (
                                    <AntCheckbox
                                        {...props}
                                        content={t('experienceConfirmation')}
                                    />
                                )}
                            </AntField>
                        )}
                        <FileUploadView
                            className={style.noBorderRow}
                            model={
                                new FileUpload(
                                    fields.attachments,
                                    membershipAppId
                                )
                            }
                        />
                        <div className={style.addUrlField}>
                            <UrlFieldView
                                model={
                                    fields.membershipApplicationLinks as UrlField
                                }
                                label={t('links')}
                                description={t('linksDescription')}
                            />
                        </div>
                        <MembershipApplicationProfessionalGroupsView
                            field={
                                fields.membershipApplicationProfessionalGroups
                            }
                        />
                        {!isEditView && (
                            <FieldPolicy field={fields.acceptPrivacyPolicy} />
                        )}
                        <SingleUserAddressesDialogsView model={userAddresses} />
                    </>
                );
            }}
        </AntForm>
    );
};

export default GraduateFormView;
