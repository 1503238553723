import MembershipApplication, {
    MembershipWizardStep
} from '../MembershipApplication';
import { observable, computed, action } from 'mobx';
import { RadioChangeEvent } from 'antd/es/radio';
import loader from 'react-mvvm/loading/loader';
import { getUserMembershipOptions } from 'model/Api/Individuals/GetUserMembershipOptionsRequest';
import { UserMembershipTypeListItemDto } from 'model/Api/Individuals/Model/UserMembershipTypeListItemDto';

class SubscriptionStep implements MembershipWizardStep {
    @observable parent: MembershipApplication;
    @observable subscription: UserMembershipTypeListItemDto | undefined;
    @observable membershipTypes: UserMembershipTypeListItemDto[] = [];

    constructor(parent: MembershipApplication) {
        this.parent = parent;
    }

    api = loader({
        getMembershipOptions: async () => await getUserMembershipOptions({})
    });

    init = async () => {
        this.membershipTypes = await this.api.getMembershipOptions();
    };

    @computed get isNextDisable(): boolean {
        return !this.subscription;
    }

    isMembershipTypeDisabled(
        membershipType: UserMembershipTypeListItemDto
    ): boolean {
        return (
            this.parent.parent.isActiveRegularMember &&
            this.parent.parent.member?.currentMembership.type.id ===
                membershipType.id
        );
    }

    @action
    onChangeSubscription = (e: RadioChangeEvent) => {
        this.subscription = e.target.value;
    };

    onNext = async () => {
        await this.parent.formStep.onActivation();
        return !!this.subscription;
    };
}

export default SubscriptionStep;
