import * as React from 'react';
import PersonalData from 'web/screen/Membership/PersonalData/PersonalData';
import moment from 'moment';
import PartyInfo from 'web/screen/Membership/PartyInfo';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import InfoRow from 'common/components/InfoRow/InfoRow';
import { Gender } from 'model/Externals';

interface InfoProps {
    model: PersonalData;
}

const Info: React.FC<InfoProps> = observer(({ model }) => {
    const { t } = useTranslation(['membership', 'common']);
    if (!model.member) {
        return null;
    }


    const getGenderText = (gender?: Gender) => {
      switch (gender) {
        case Gender.female:
            return t('membership:genderFemale');
            case Gender.male:
              return t('membership:genderMale');
              case Gender.other:
                return t('membership:genderOther');
    }

    return '-';

    }

    return (
        <>
            <InfoRow title={t('membership:birthDate')}>
                {!!model.member.birthDate
                    ? moment(model.member.birthDate).format('DD MMMM YYYY')
                    : '-'}
            </InfoRow>
            <InfoRow title={t('membership:gender')}>
                {getGenderText(model.member.gender)}
            </InfoRow>
            <InfoRow title={t('membership:privateAddress')}>
                <PartyInfo
                    party={model.parent.getParty(model.member.privatePartyId)}
                />
            </InfoRow>
        </>
    );
});

export default Info;
