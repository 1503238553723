import MembershipApplication, {
    MembershipWizardStep
} from '../MembershipApplication';
import { action, computed, observable } from 'mobx';
import { PaymentMethod, SubscriptionType } from 'model/Externals';
import loader from 'react-mvvm/loading/loader';
import { homeRoute } from 'web/routes';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import { submitApplicationAndPayOnline } from 'model/Api/Members/SubmitApplicationAndPayOnlineRequest';
import { submitApplicationAndPayWithInvoice } from 'model/Api/Members/SubmitApplicationAndPayWithInvoiceRequest';
import { createOrUpdateMembershipApplication } from 'model/Api/Members/CreateOrUpdateMembershipApplicationRequest';
import { MembershipApplicationDto } from 'model/Api/Members/Model/MembershipApplicationDto';
import { getMembershipPrice } from 'model/Api/Members/GetMembershipPriceRequest';
import { MembershipPriceResponse } from 'model/Api/Members/Model/MembershipPriceResponse';
import { submitChangeApplication } from 'model/Api/Members/SubmitChangeApplicationRequest';

class PaymentStep implements MembershipWizardStep {
    @observable parent: MembershipApplication;
    @observable subscriptionType: SubscriptionType | undefined;
    @observable userAddresses: UserAddresses;
    @observable paymentMethod: PaymentMethod | undefined;
    @observable isPaymentTermsAccepted: boolean = false;
    @observable membershipPrice: MembershipPriceResponse | undefined;

    constructor(parent: MembershipApplication) {
        this.parent = parent;
        this.userAddresses = new UserAddresses(this.parent, true, true);
    }

    onActivation = async () => {
        await this.userAddresses.init();
        window.scrollTo({ top: 0 });
        if (this.subscription) {
            this.membershipPrice = await this.api.getMembershipPrice(
                this.subscription.id
            );
        }
        this.subscriptionType = this.parent.initApplication?.subscriptionType;
        this.paymentMethod = this.parent.initApplication?.paymentMethod;
        this.userAddresses.onSelectAddress(
            this.parent.initApplication?.privatePartyId ??
                this.parent.initApplication?.employerPartyId ??
                0
        );
    };

    api = loader({
        submitApplicationAndPayOnline: async (
            membershipApplicationId: number,
            membershipTypeId: number,
            partyId: number,
            paymentMethod: PaymentMethod,
            subscriptionType: SubscriptionType
        ) =>
            submitApplicationAndPayOnline({
                membershipApplicationId,
                membershipTypeId,
                partyId,
                paymentMethod,
                subscriptionType,
                paymentTermsAccepted: this.isPaymentTermsAccepted
            }),
        submitApplicationAndPayWithInvoice: async (
            membershipApplicationId: number,
            membershipTypeId: number,
            partyId: number,
            paymentMethod: PaymentMethod,
            subscriptionType: SubscriptionType
        ) =>
            submitApplicationAndPayWithInvoice({
                membershipApplicationId,
                membershipTypeId,
                partyId,
                paymentMethod,
                subscriptionType,
                paymentTermsAccepted: this.isPaymentTermsAccepted
            }),
        submitChangeApplication: async (
            membershipApplicationId: number,
            membershipTypeId: number,
            partyId: number,
            paymentMethod: PaymentMethod,
            subscriptionType: SubscriptionType
        ) =>
            submitChangeApplication({
                membershipApplicationId,
                membershipTypeId,
                partyId,
                paymentMethod,
                subscriptionType,
                paymentTermsAccepted: this.isPaymentTermsAccepted
            }),
        updateMembershipApplication: async (
            membershipApplication: MembershipApplicationDto
        ) => createOrUpdateMembershipApplication({ membershipApplication }),
        getMembershipPrice: (typeId: number) =>
            getMembershipPrice({
                typeId
            })
    });

    @action
    onTermsCheck = (value: boolean) => {
        this.isPaymentTermsAccepted = value;
    };

    @action
    onPaymentMethodCheck = (values: PaymentMethod) => {
        this.paymentMethod = values;
    };

    onNext = async () => {
        return true;
    };

    @computed get isNextDisable() {
        return true;
    }

    @computed get initApplication() {
        return this.parent.initApplication;
    }

    @computed get subscription() {
        return this.parent.subscriptionStep.subscription;
    }

    @computed get isReadonly() {
        return !!this.parent.parent?.isActiveRegularMember;
    }

    @computed get isInvoicePaymentDisabled() {
        return (
            this.isReadonly || this.subscriptionType !== SubscriptionType.annual
        );
    }

    @action
    onSubscriptionCheck = (value: SubscriptionType) => {
        this.paymentMethod = undefined;
        this.subscriptionType = value;
    };

    onPayNow = async () => {
        if (!!this.initApplication) {
            await this.api.updateMembershipApplication(this.initApplication);
            if (
                !!this.userAddresses.selectedParty &&
                !!this.paymentMethod &&
                !!this.subscriptionType &&
                !!this.subscription
            ) {
                const onlinePaymentToken = await this.api.submitApplicationAndPayOnline(
                    this.initApplication.id,
                    this.subscription.id,
                    this.userAddresses.selectedParty,
                    this.paymentMethod,
                    this.subscriptionType
                );

                if (onlinePaymentToken) {
                    window.location.href = onlinePaymentToken.url;
                }
            }
        }
    };

    onPayByInvoice = async () => {
        if (!!this.initApplication) {
            await this.api.updateMembershipApplication(this.initApplication);

            if (
                !!this.userAddresses.selectedParty &&
                !!this.paymentMethod &&
                !!this.subscriptionType &&
                !!this.subscription
            ) {
                const invoicePayment = await this.api.submitApplicationAndPayWithInvoice(
                    this.initApplication.id,
                    this.subscription.id,
                    this.userAddresses.selectedParty,
                    this.paymentMethod,
                    this.subscriptionType
                );

                homeRoute.historyPush(
                    `/invoicepaymentstatus?status=${invoicePayment.status}&orderId=${invoicePayment.orderId}&isPaymentForMembership=true`
                );
            }
        }
    };

    onChange = async () => {
        if (!!this.initApplication) {
            await this.api.updateMembershipApplication(this.initApplication);

            if (
                !!this.userAddresses.selectedParty &&
                !!this.paymentMethod &&
                !!this.subscriptionType &&
                !!this.subscription
            ) {
                await this.api.submitChangeApplication(
                    this.initApplication.id,
                    this.subscription.id,
                    this.userAddresses.selectedParty,
                    this.paymentMethod,
                    this.subscriptionType
                );

                window.location.href = '/membership';
            }
        }
    };
}

export default PaymentStep;
