import * as React from 'react';
import UserInfo from './UserInfo';
import style from './UserInfo.module.scss';
import { Button, Typography } from 'antd';
import { observer } from 'mobx-react';
import Flex from 'common/components/Flex';
import { useTranslation } from 'react-i18next';
import { PaymentMethod, SubscriptionType } from 'model/Externals';

interface MembershipDataProps {
    model: UserInfo;
}

const MembershipData: React.FC<MembershipDataProps> = observer(({ model }) => {
    const { t } = useTranslation(['homePage', 'common', 'membership']);
    return (
        <>
            <div className={style.titleBox}>
                <Typography.Title level={3}>
                    {t('homePage:membership')}
                </Typography.Title>
            </div>
            <div className={style.list}>
                <span className={style.listItem}>
                    {t('homePage:membershipStatus')}
                </span>
                <span className={style.listItem}>
                    {model.member?.isActive
                        ? t('homePage:activeMembership')
                        : t('homePage:inActiveMembership')}
                </span>
            </div>
            <div className={style.list}>
                <span className={style.listItem}>
                    {t('homePage:membershipType')}
                </span>
                <span className={style.listItem}>
                    {model.member?.currentMembership?.type.name}
                </span>
            </div>
            <div className={style.list}>
                <span className={style.listItem}>
                    {t('homePage:subscriptionType')}
                </span>
                <span className={style.listItem}>
                    {!model.member?.currentSubscription
                        ? '-'
                        : model.member.currentSubscription
                              .subscriptionType === SubscriptionType.annual
                        ? t('membership:annual')
                        : t('membership:monthly')}
                </span>
            </div>
            <div className={style.list}>
                <span className={style.listItem}>
                    {t('homePage:paymentType')}
                </span>
                <span className={style.listItem}>
                    {model?.member?.currentSubscription ? (model.member.currentSubscription
                        .paymentMethod === PaymentMethod.bambora
                        ? t('common:payByCart')
                        : t('common:payByInvoice')) : '-'}
                </span>
            </div>
            <Flex justifyContent={'flex-end'}>
                <Button
                    type={'text'}
                    size={'small'}
                    className={style.edit}
                    onClick={model.parent.showMembershipPage}>
                    {t('homePage:allMembershipInformation')}
                </Button>
            </Flex>
        </>
    );
});

export default MembershipData;
