import * as React from 'react';
import { FormField } from 'react-mvvm/forms';
import { MembershipApplicationRequirement } from 'model/Externals';
import { Radio } from 'antd';
import AntField from 'common/components/antHelpers/AntField';
import style from './MembershipRequirementField.module.scss';
import { useTranslation } from 'react-i18next';

interface MembershipRequirementFieldProps {
    field: FormField<MembershipApplicationRequirement | undefined>;
}
const MembershipRequirementField: React.FC<MembershipRequirementFieldProps> = ({
    field
}) => {
    const { t } = useTranslation('membership');
    return (
        <AntField
            field={field}
            label={t('membershipApplicationRequirementLabel')}
            description={t('membershipApplicationRequirementDescription')}>
            {(props) => (
                <Radio.Group
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}>
                    <Radio
                        value={MembershipApplicationRequirement.bachelorsDegree}
                        className={style.radio}>
                        {t('bachelorsDegreeRequirement')}
                    </Radio>
                    <Radio
                        value={
                            MembershipApplicationRequirement.professionallyActive
                        }
                        className={style.radio}>
                        {t('professionallyActiveRequirement')}
                    </Radio>
                    <Radio
                        value={MembershipApplicationRequirement.schoolEducation}
                        className={style.radio}>
                        {t('schoolEducationRequirement')}
                    </Radio>
                    <Radio
                        value={MembershipApplicationRequirement.workExperience}
                        className={style.radio}>
                        {t('workExperienceRequirement')}
                    </Radio>
                </Radio.Group>
            )}
        </AntField>
    );
};

export default MembershipRequirementField;
