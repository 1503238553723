import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {SubscriptionDto} from "../Members/Model/SubscriptionDto"

export interface StopAutoRenewalForCurrentSubscriptionRequest {
}
export const stopAutoRenewalForCurrentSubscription = (stopAutoRenewalForCurrentSubscriptionRequest: StopAutoRenewalForCurrentSubscriptionRequest): Promise<SubscriptionDto> => {
    return send("StopAutoRenewalForCurrentSubscription", stopAutoRenewalForCurrentSubscriptionRequest)
}
