import { useTranslation } from 'react-i18next';
import * as React from 'react';
import FileUpload from './FileUpload';
import AntField from 'common/components/antHelpers/AntField';
import { FieldInputProps } from 'common/components/antHelpers/AntField/AntField';
import AntUploadFile from 'common/components/antHelpers/AntUploadFile';
import { UploadFile } from 'antd/lib/upload/interface';
import {
    FilePdfOutlined,
    SoundOutlined,
    VideoCameraOutlined
} from '@ant-design/icons';
import { MembershipApplicationAttachmentDto } from 'model/Api/Members/Model/MembershipApplicationAttachmentDto';

interface FileUploadViewProps {
    model: FileUpload;
    disabled?: boolean;
    className?: string;
}

const customIconRender = (file: UploadFile<any>) => {
    switch (file.type) {
        case 'application/pdf':
            return <FilePdfOutlined />;
        case 'audio/mpeg': //mp3
            return <SoundOutlined />;
        case 'video/mp4':
            return <VideoCameraOutlined />;
        default:
            return undefined;
    }
};

const FileUploadView: React.FC<FileUploadViewProps> = ({
    model,
    disabled,
    className
}) => {
    const { t } = useTranslation('membership');
    const { filesField } = model;
    return (
        <AntField
            className={className}
            field={model.filesField}
            label={t('filesLabel')}
            description={t('filesDescription')}>
            {(
                props: FieldInputProps<
                    MembershipApplicationAttachmentDto[] | undefined
                >
            ) => {
                const defaultFields: UploadFile<any>[] = !filesField.value
                    ? []
                    : filesField.value;
                return (
                    <AntUploadFile
                        disabled={disabled}
                        defaultFileList={[...defaultFields]}
                        onRemove={model.onRemoveFile(filesField)}
                        customRequest={model.getFileUploadRequest(filesField)}
                        multiple={true}
                        iconRender={customIconRender}
                        beforeUpload={model.validateFileBeforeUpload}
                    />
                );
            }}
        </AntField>
    );
};

export default FileUploadView;
