import * as React from 'react';
import style from './Membership.module.scss';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import SubscriptionInfoWithPeriod from 'web/screen/Membership/SubscriptionInfoWithPeriod';
import Flex from 'common/components/Flex';
import { PaymentMethod } from '../../../model/Externals';
import Party from 'common/components/UserAddresses/Party';
import ArrowPanel from 'web/components/ArrowPanel';
import InfoRow from 'common/components/InfoRow/InfoRow';
import { MemberDto } from 'model/Api/Members/Model/MemberDto';

interface PaymentInfoProps {
  member: MemberDto;
  updateCreditCart: () => void;
  onChangeSubscription: () => void;
  onChangeBillingAddress: () => void;
  onAddCreditCart: () => void;
}

const PaymentInfo: React.FC<PaymentInfoProps> = ({
  member,
  updateCreditCart,
  onChangeSubscription,
  onChangeBillingAddress,
  onAddCreditCart
}) => {
  const { t } = useTranslation(['membership', 'common']);

  const creditCartInfoRow = (
    <Flex justifyContent={'space-between'}>
      <span>{t('membership:cardNumber')}</span>
      <Button
        type={'link'}
        onClick={updateCreditCart}
        className={style.titleLink}>
        {t('membership:updateCartInfo')}
      </Button>
    </Flex>
  );

  const billingAddressTitle = (
    <Flex justifyContent={'space-between'}>
      <span>{t('membership:billingAddress')}</span>
      <Button
        type={'link'}
        onClick={onChangeBillingAddress}
        className={style.titleLink}>
        {t('membership:changeBillingAddress')}
      </Button>
    </Flex>
  );

  return (
    <div className={style.dataContent}>
      {!!member?.currentSubscription && <>
        <div className={style.title}>
          <span className={style.titleTxt}>
            {t('membership:paymentInfo')}
          </span>
        </div>
        <SubscriptionInfoWithPeriod
          isActive={member.isActive}
          subscription={member.currentSubscription}
          onChangeSubscription={onChangeSubscription}
        /></>}
      {!!member?.currentSubscription && member.currentSubscription.paymentMethod ===
        PaymentMethod.bambora && (
          <>
            {!!member.currentSubscription.cardNumber && (
              <InfoRow titleRow={creditCartInfoRow}>
                {member.currentSubscription.cardNumber}
                <div>
                  <span>{t('membership:cardExpiration')}</span>{' '}
                  {`${member.currentSubscription.cardExpirationMonth}. 20${member.currentSubscription.cardExpirationYear}`}
                </div>
              </InfoRow>
            )}

            {!member.currentSubscription.cardNumber && (
              <div className={style.dataContent}>
                <ArrowPanel
                  contentBox={
                    <span className={style.addCardTxt}>
                      {t('membership:addCartButton')}
                    </span>
                  }
                  onClick={onAddCreditCart}
                  containerStyle={style.addCard}
                />
              </div>
            )}
          </>
        )}
      {!!member?.currentSubscription && <InfoRow titleRow={billingAddressTitle}>
        <Party
          party={member.currentSubscription.billingAddress}
          customStyle={style.partyStyle}
        />
      </InfoRow>}
    </div>
  );
};

export default PaymentInfo;
