import { Page } from 'react-mvvm';
import loader from 'react-mvvm/loading/loader';
import HomePage from 'web/screen/HomePage/HomePage';
import { computed, observable } from 'mobx';
import MembershipApplication from 'web/screen/MembershipApplication/MembershipApplication';
import { getParties } from 'model/Api/Parties/GetPartiesRequest';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';
import { getUserMembershipOptions } from 'model/Api/Individuals/GetUserMembershipOptionsRequest';
import MembershipPaymentPage from 'web/screen/MembershipPaymentPage/MembershipPaymentPage';
import CancelMembershipDialog from 'web/screen/Membership/CancelMembershipDialog/CancelMembershipDialog';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import PersonalData from 'web/screen/Membership/PersonalData/PersonalData';
import SchoolInfo from 'web/screen/Membership/SchoolInfo/SchoolInfo';
import { MembershipKind, Gender, MemberStatus } from 'model/Externals';
import EmployeeData from 'web/screen/Membership/EmployeeData/EmployeeData';
import ProfessionalInfo from 'web/screen/Membership/ProfessionalInfo/ProfessionalInfo';
import UpdateCartInfoDialog from 'web/screen/Membership/UpdateCartInfoDialog/UpdateCartInfoDialog';
import { getMember } from 'model/Api/Members/GetMemberRequest';
import { MemberDto } from 'model/Api/Members/Model/MemberDto';
import { MembershipApplicationDto } from 'model/Api/Members/Model/MembershipApplicationDto';
import { UserMembershipTypeListItemDto } from 'model/Api/Individuals/Model/UserMembershipTypeListItemDto';
import { createOrUpdateMembershipApplication } from 'model/Api/Members/CreateOrUpdateMembershipApplicationRequest';
import { getUserInfo } from 'model/Api/Individuals/GetUserInfoRequest';
import { UserInfoDto } from 'model/Api/Individuals/Model/UserInfoDto';
import { changePayingParty } from 'model/Api/Members/ChangePayingPartyRequest';
import AddCartInformationDialog from 'web/screen/Membership/AddCartInformationDialog/AddCartInformationDialog';
import { stopAutoRenewalForCurrentSubscription } from 'model/Api/Payments/StopAutoRenewalForCurrentSubscriptionRequest';
import { changeCreditCard } from '../../../model/Api/Payments/ChangeCreditCardRequest';
import { updateMemberPersonalData } from '../../../model/Api/Members/UpdateMemberPersonalDataRequest';
import { updateMemberEmploymentData } from '../../../model/Api/Members/UpdateMemberEmploymentDataRequest';
import { updateMemberInterests } from '../../../model/Api/Members/UpdateMemberInterestsRequest';
import { updateMemberEducationData } from 'model/Api/Members/UpdateMemberEducationDataRequest';
import ChangeBillingAddressDialog from '../../../common/components/ChangeBillingAddressDialog/ChangeBillingAddressDialog';
import MembershipReapplyPage from '../MembershipReapplyPage/MembershipReapplyPage';
import AlmostMember from './AlmostMember/AlmostMember';

class Membership extends Page<
    | CancelMembershipDialog
    | ConfirmationModal
    | UpdateCartInfoDialog
    | ChangeBillingAddressDialog
    | AddCartInformationDialog
> {
    @observable member: MemberDto | undefined;
    @observable parent: HomePage;
    @observable userParties: PartyDto[] = [];
    @observable membershipOptions: UserMembershipTypeListItemDto[] = [];
    @observable personaData: PersonalData | undefined;
    @observable schoolInfo: SchoolInfo | undefined;
    @observable employeeInfo: EmployeeData | undefined;
    @observable professionalInfo: ProfessionalInfo | undefined;
    @observable user: UserInfoDto | undefined;
    @observable almostMember: AlmostMember;

    constructor(parent: HomePage) {
        super();

        this.parent = parent;
        this.almostMember = new AlmostMember(this.userName ?? '', this);
    }

    api = loader({
        getMember: async () => getMember({}),
        getUserParty: async () => getParties({}),
        getUserInfo: async () => getUserInfo({}),
        getUserMembershipOptions: async () => getUserMembershipOptions({}),
        changeCreditCard: () => changeCreditCard({}),
        changeBillingAddress: async (payingPartyRef: number) =>
            changePayingParty({ payingPartyRef }),
        stopRenewalSubscription: async () =>
            stopAutoRenewalForCurrentSubscription({}),
        updateMemberPersonalData: async (
            birthDate: string,
            gender: Gender,
            privatePartyId: number
        ) => updateMemberPersonalData({ birthDate, gender, privatePartyId }),
        updateMemberEmploymentData: async (
            workPosition: string,
            employerPartyId: number
        ) => updateMemberEmploymentData({ workPosition, employerPartyId }),
        updateMemberInterests: async (interests: string[]) =>
            updateMemberInterests({
                interests
            }),
        updateMemberEducationData: async (
            schoolName: string,
            expectedGraduationDate?: string
        ) => updateMemberEducationData({ schoolName, expectedGraduationDate })
    });

    apiSaveMembership = loader({
        updateMembershipApplication: async (
            membershipApplication: MembershipApplicationDto
        ) =>
            createOrUpdateMembershipApplication({
                membershipApplication
            })
    });

    protected async onActivated(): Promise<any> {
        await this.almostMember.activate();
        if (!this.childPage || !this.member) {
            this.membershipOptions = await this.api.getUserMembershipOptions();
            this.userParties = await this.api.getUserParty();
            this.member = await this.api.getMember();
            this.user = await this.api.getUserInfo();

            this.personaData = new PersonalData(this);
            await this.personaData.onInit();

            if (
                !!this.membershipType &&
                this.membershipType.membershipKind === MembershipKind.student
            ) {
                this.schoolInfo = new SchoolInfo(this);
                await this.schoolInfo.onInit();
            }

            if (
                (!!this.membershipType &&
                    this.membershipType.membershipKind ===
                        MembershipKind.professional) ||
                this.membershipType?.membershipKind === MembershipKind.associate
            ) {
                this.employeeInfo = new EmployeeData(this);
                await this.employeeInfo.onInit();
            }

            if (
                !!this.membershipType &&
                this.membershipType.membershipKind ===
                    MembershipKind.professional
            ) {
                this.professionalInfo = new ProfessionalInfo(this);
                await this.professionalInfo.onInit();
            }

            return;
        }
    }

    @computed get isMember() {
        return !!this.member;
    }

    @computed get isActiveRegularMember() {
        return (
            !!this.isMember &&
            this.member?.currentMembership?.memberStatus === MemberStatus.active &&
            !this.member?.currentMembership.type.isPrivate
        );
    }

    @computed get isSubscriptionActive() {
      return (
          !!this.member &&
          !!this.member.currentSubscription?.autoRenew
      );
  }

    showApplicationPage = async () => {
        return this.showChildPage(new MembershipApplication(this));
    };

    onChangeMembership = async () => {
        return this.showChildPage(new MembershipApplication(this));
    };

    onChangeSubscriptionData = async () => {
        await this.showMembershipPaymentPage();
    };

    onApplyForMembership = async () => {
        return this.showChildPage(new MembershipApplication(this));
    };
    onAddCart = async () => {
        await this.showModal<AddCartInformationDialog, boolean | undefined>(
            (close) =>
                new AddCartInformationDialog(async () => {
                    const result = await this.api.changeCreditCard();
                    window.location.href = result.url;
                }, close)
        );
    };

    onUpdateCart = async () => {
        await this.showModal<UpdateCartInfoDialog, boolean | undefined>(
            (close) =>
                new UpdateCartInfoDialog(async () => {
                    const result = await this.api.changeCreditCard();
                    window.location.href = result.url;
                }, close)
        );
    };

    onChangeBillingAddress = async () => {
        await this.showModal<
            ChangeBillingAddressDialog,
            PartyDto[] | undefined
        >(
            (close) =>
                new ChangeBillingAddressDialog(
                    async (ref: number) => {
                        await this.api.changeBillingAddress(ref);
                        this.member = await this.api.getMember();
                    },
                    close,
                    this as Page,
                    this.member?.currentSubscription.billingAddress
                )
        );
    };

    getParty = (id: number | undefined) => {
        if (!this.userParties.length || !id) {
            return;
        }

        return this.userParties.find((party) => party.id === id);
    };

    showMembershipPaymentPage = () => {
        return this.showChildPage(new MembershipPaymentPage(this));
    };

    @computed get userName() {
        return `${this.user?.family ?? ''} ${this.user?.given ?? ''}`;
    }

    @computed get currentSubscription() {
        return !!this.member ? this.member.currentSubscription : undefined;
    }

    @computed get membershipType() {
        if (this.member && this.member.currentMembership) {
            return this.member.currentMembership.type;
        }
        return undefined;
    }

    onStopRenewalSubscription = async () => {
        if (this.member?.currentSubscription.autoRenew) {
            await this.showModal<CancelMembershipDialog, boolean | undefined>(
                (close) =>
                    new CancelMembershipDialog(async () => {
                        const result = await this.api.stopRenewalSubscription();
                        if (!!this.member) {
                            this.member.currentSubscription = result;
                        }
                    }, close)
            );
        }
    };
}

export default Membership;
