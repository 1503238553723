import * as React from 'react';
import style from './PaymentCheckbox.module.scss';
import classNames from 'classnames';
import { Tooltip, Typography } from 'antd';
import Flex from 'common/components/Flex';

interface PaymentCheckboxProps {
    selected: boolean;
    onSelect: () => void;
    title: string;
    dsc: string;
    price?: string;
    priceDsc?: string;
    isBorder?: boolean;
    isDisabled?: boolean;
    toolTip?: string;
}

const PaymentCheckbox: React.FC<PaymentCheckboxProps> = ({
    selected,
    onSelect,
    title,
    dsc,
    price,
    priceDsc,
    isBorder = true,
    isDisabled = false,
    toolTip
}) => {
    const boxStyle = classNames(style.content, {
        [style.bordered]: isBorder,
        [style.selected]: isBorder && selected,
        [style.isDisabled]: isDisabled
    });

    const radioStyle = classNames(style.radio, {
        [style.radioSelected]: selected
    });
    return (
        <Tooltip title={toolTip}>
            <div className={boxStyle} onClick={() => !isDisabled && onSelect()}>
                <span className={radioStyle} />
                <div>
                    <Typography.Text className={style.title}>
                        {title}
                    </Typography.Text>
                    {!!price?.length && !priceDsc?.length && (
                        <Typography.Text className={style.price}>
                            {price}
                        </Typography.Text>
                    )}
                    {!!price?.length && !!priceDsc?.length && (
                        <Flex
                            justifyContent={'flex-start'}
                            alignItems={'center'}>
                            <Typography.Text className={style.price}>
                                {price}
                            </Typography.Text>
                            <Typography.Text className={style.priceDsc}>
                                {priceDsc}
                            </Typography.Text>
                        </Flex>
                    )}
                    {!price?.length && !!priceDsc?.length && (
                        <Typography.Text className={style.title}>
                            {priceDsc}
                        </Typography.Text>
                    )}
                    <Typography.Text className={style.dsc}>
                        {dsc}
                    </Typography.Text>
                </div>
            </div>
        </Tooltip>
    );
};

export default PaymentCheckbox;
