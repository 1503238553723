import * as React from 'react';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import FieldPolicy from 'web/screen/MembershipApplication/FormStep/FieldPolicy';
import GenderField from 'common/components/antHelpers/GenderField/GenderField';
import UrlFieldView from 'common/components/antHelpers/UrlField/UrlFieldView';
import UrlField from 'common/components/antHelpers/UrlField/UrlFied';
import FileUpload from '../../../../../common/components/antHelpers/FileUpload/FileUpload';
import FileUploadView from '../../../../../common/components/antHelpers/FileUpload/FileUploadView';
import { useTranslation } from 'react-i18next';
import SingleUserAddressesDialogsView from 'common/components/UserAddresses/SingleUserAddressesDialogsView';
import SingleUsersAddressDropdown from 'common/components/SignleUserAddressDropdown/SingleUsersAddressDropdown';
import style from './ProfessionalForm.module.scss';
import rootStyle from '../FormStep.module.scss';
import BirthDatePickerView from 'common/components/BirthDatePicker/BirthDatePickerView';
import BirthDatePicker from 'common/components/BirthDatePicker/BirthDatePicker';
import { Form } from 'react-mvvm/forms';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import MembershipApplicationProfessionalGroupsView from './MembershipApplicationProfessionalGroupsView';
import MembershipRequirementField from 'common/components/antHelpers/MembershipRequirementField';

interface ProfessionalFormViewProps {
    form: Form<any>;
    userAddresses: UserAddresses | undefined;
    membershipAppId: number;
    isEditView?: boolean;
}

const ProfessionalFormView: React.FC<ProfessionalFormViewProps> = ({
    form,
    userAddresses,
    membershipAppId,
    isEditView = false
}) => {
    const { t } = useTranslation('membership');
    return (
        <AntForm form={form} layout={'vertical'}>
            {(fields) => {
                return (
                    <>
                        <div className={rootStyle.row}>
                            <BirthDatePickerView
                                model={fields.birthDate as BirthDatePicker}
                            />
                        </div>
                        {!!fields.gender && (
                            <GenderField field={fields.gender} />
                        )}
                        <AntField
                            field={fields.privatePartyId}
                            label={t('selectPrivateAddress')}>
                            {(props) => {
                                return (
                                    <SingleUsersAddressDropdown
                                        model={userAddresses}
                                        type={'private'}
                                    />
                                );
                            }}
                        </AntField>
                        <AntField
                            field={fields.employerPartyId}
                            label={t('selectCompanyAddress')}>
                            {(props) => {
                                return (
                                    <SingleUsersAddressDropdown
                                        model={userAddresses}
                                        type={'company'}
                                    />
                                );
                            }}
                        </AntField>
                        {!!fields.workPosition && (
                            <AntField
                                field={fields.workPosition}
                                label={t('workPosition')}
                                description={
                                    'Dersom du ikke har en offisiell stillingstittel, oppgi en beskrivelse av hva du arbeider\n' +
                                    'med, f.eks illustratør, grafisk designer, e.l.'
                                }>
                                {(props) => <AntTextInput {...props} />}
                            </AntField>
                        )}
                        <MembershipRequirementField
                            field={fields.membershipApplicationRequirement}
                        />
                        <FileUploadView
                            className={style.noBorderRow}
                            model={
                                new FileUpload(
                                    fields.attachments,
                                    membershipAppId
                                )
                            }
                        />
                        <div className={style.addUrlField}>
                            <UrlFieldView
                                model={
                                    fields.membershipApplicationLinks as UrlField
                                }
                                label={t('links')}
                                description={t('linksDescription')}
                            />
                        </div>
                        <MembershipApplicationProfessionalGroupsView
                            field={
                                fields.membershipApplicationProfessionalGroups
                            }
                        />
                        {!isEditView && (
                            <FieldPolicy
                                field={fields.acceptPrivacyPolicy}
                                offset={6}
                            />
                        )}
                        <SingleUserAddressesDialogsView model={userAddresses} />
                    </>
                );
            }}
        </AntForm>
    );
};

export default ProfessionalFormView;
