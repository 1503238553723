import * as React from 'react';
import style from './Membership.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button } from 'antd';
import Flex from 'common/components/Flex';
import InfoRow from 'common/components/InfoRow/InfoRow';
import { PaymentMethod } from 'model/Externals';
import { SubscriptionDto } from 'model/Api/Members/Model/SubscriptionDto';

interface SubscriptionInfoWithPeriodProps {
  subscription: SubscriptionDto;
  isActive: boolean;
  onChangeSubscription: () => void;
}

const SubscriptionInfoWithPeriod: React.FC<SubscriptionInfoWithPeriodProps> = ({
  subscription,
  onChangeSubscription,
  isActive
}) => {
  const { t } = useTranslation(['membership', 'common']);

  return (
    <InfoRow>
      <div>
        <Flex justifyContent={'space-between'}>
          <span
            className={
              style.rowTxt
            }>{`Betalinsperiode: ${subscription?.subscriptionType}`}</span>
          {isActive && <Button
            type={'link'}
            onClick={onChangeSubscription}
            className={style.titleLink}>
            {t('changeSubscriptionData')}
          </Button>}
        </Flex>
        <span className={style.rowTxt}>{`${t(
          'membership:selectedPaymentMethod'
        )} ${subscription?.paymentMethod ===
            PaymentMethod.bambora
            ? t('common:payByCart')
            : t('common:payByInvoice')
          }`}</span>
        {subscription?.paidTo ?
          (<span className={style.rowTxt}>{`${t('membership:BillingTil')} ${moment(
            subscription.paidTo
          ).format('DD.MM.YYYY')}`}</span>)
          : (<span>{'-'}</span>)}
        <Button
          type={'link'}
          className={style.btnLink}
          onClick={() => {
            window.location.href = '/invoices';
          }}>
          {t('membership:seeYourInvoices')}
        </Button>
      </div>
    </InfoRow>
  );
};

export default SubscriptionInfoWithPeriod;
