import * as React from 'react';
import Invoices from './Invoices';
import PageWrapper from 'web/components/PageWrapper';
import PageLoader from 'common/components/PageLoader';
import { Button, Spin, Tag, Tooltip } from 'antd';
import { PaymentStatus } from 'model/Externals';
import { AntTable } from 'common/components/antHelpers/AntTable/AntTable';
import { DownloadOutlined } from '@ant-design/icons';
import Flex from 'common/components/Flex';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PageTitle from 'web/components/PageTitle';
import { PaymentListItemDto } from 'model/Api/Payments/Model/PaymentListItemDto';
import style from './Invoices.module.scss';
import { observer } from 'mobx-react';

interface InvoicesViewProps {
  model: Invoices;
}

const getColumns = (modal: Invoices, t: any) => {
  return [
    {
      title: t('refNo'),
      dataIndex: modal.paymentsList.field('id')
    },
    {
      title: t('invoiceNumber'),
      dataIndex: modal.paymentsList.field('invoiceNumber')
    },
    {
      title: t('date'),
      dataIndex: modal.paymentsList.field('transactionDate'),
      render: (date: string) => (
        <span>{moment(date).format('DD.MM.YYYY')}</span>
      )
    },
    {
      title: t('invoices:amount'),
      dataIndex: modal.paymentsList.field('totalAmountIncVat')
    },
    {
      title: t('status'),
      dataIndex: modal.paymentsList.field('paymentStatus'),
      render: (status: PaymentStatus, record: PaymentListItemDto) => {
        let statTxt = '';
        let isDueDate = false;
        let tagType = 'default';
        switch (status) {
          case PaymentStatus.manuallyConfirmed:
          case PaymentStatus.paid:
            statTxt = t('statusPaid');
            tagType = 'success';
            break;
          case PaymentStatus.pending:
            statTxt = t('statusPending');
            isDueDate = true;
            tagType = 'processing';
            break;
          case PaymentStatus.failed:
            statTxt = t('statusFailed');
            isDueDate = true;
            tagType = 'error';
            break;
          case PaymentStatus.canceled:
            statTxt = t('statusCanceled');
            tagType = 'warning';
            break;
        }

        return <Tag color={tagType}>{statTxt}</Tag>;
      }
    },
    {
      title: t('dueDate'),
      dataIndex: modal.paymentsList.field('paymentStatus'),
      render: (status: PaymentStatus, record: PaymentListItemDto) => {
        if (
          status === PaymentStatus.pending ||
          status === PaymentStatus.failed
        ) {
          return (
            <span>{`${t('dueDate')}: ${moment(
              record.dueDate
            ).format('DD.MM.YYYY')}`}</span>
          );
        }
        return '-';
      }
    },
    {
      title: t('invoices:description'),
      dataIndex: modal.paymentsList.field('description')
    },
    {
      title: '',
      dataIndex: modal.paymentsList.field('paymentStatus'),
      render: (item: PaymentStatus, record: PaymentListItemDto) => {
        return (
          record.isDownloadAvailable ? (
            <Button
              onClick={modal.onDownloadClicked}
              href={`/api/DownloadInvoice?paymentId=${record.id}&token=${modal.token}`}
              download>
              {modal.isLoading && (
                <Spin
                  size={'small'}
                  className={style.spinnerStyle}
                />
              )}
              <DownloadOutlined />
            </Button>
          ) : (
            <Tooltip title={t('invoices:unableToDownload')} placement="top" overlayClassName={style.tooltip}>
              <Button disabled>
                <DownloadOutlined />
              </Button>
            </Tooltip>

          )

        );
      }
    }
  ];
};

const InvoicesView: React.FC<InvoicesViewProps> = observer(({ model }) => {
  const { t } = useTranslation('invoices');
  return (
    <PageWrapper>
      <PageTitle title={t('title')} headerNumber={2} />
      <PageLoader loader={model.api}>
        <AntTable
          className={style.table}
          locale={{
            emptyText: t('empty')
          }}
          columns={getColumns(model, t)}
          list={model.paymentsList}
          pagination={false}
        />
      </PageLoader>
    </PageWrapper>
  );
});

export default InvoicesView;
