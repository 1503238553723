import MembershipApplication, {
    MembershipWizardStep
} from 'web/screen/MembershipApplication/MembershipApplication';
import { computed, observable } from 'mobx';
import { MembershipKind } from 'model/Externals';
import {
    BindableForm,
    Form,
    FormField,
    RequiredFieldValidator
} from 'react-mvvm/forms';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import StudentForm from 'web/screen/MembershipApplication/FormStep/StudentForm/StudentForm';
import ProfessionalForm from 'web/screen/MembershipApplication/FormStep/ProfessionalForm/ProfessionalForm';
import AssociateForm from 'web/screen/MembershipApplication/FormStep/AssociateForm/AssociateForm';
import { MembershipApplicationDto } from 'model/Api/Members/Model/MembershipApplicationDto';
import GraduateForm from './GraduateForm/GraduateForm';
import UrlField from 'common/components/antHelpers/UrlField/UrlFied';
import { MembershipApplicationAttachmentDto } from 'model/Api/Members/Model/MembershipApplicationAttachmentDto';
import i18next from 'i18next';
import { MembershipApplicationLinkDto } from 'model/Api/Members/Model/MembershipApplicationLinkDto';

export interface MembershipWizardForm extends MembershipWizardStep {
    onSelectAddress: () => void;
    form: BindableForm<MembershipApplicationDto, any> | Form<any>;
    formValues: Partial<MembershipApplicationDto>;
}

class FormStep implements MembershipWizardStep {
    parent: MembershipApplication;
    @observable userAddresses: UserAddresses | undefined;
    @observable applicationForm: MembershipWizardForm | undefined;
    dateFormat: string = 'DD-MM-YYYY';

    constructor(parent: MembershipApplication) {
        this.parent = parent;
    }

    onActivation = async () => {
        switch (this.subscriptionType) {
            case MembershipKind.student:
                this.applicationForm = new StudentForm(this);
                break;
            case MembershipKind.graduate:
                this.applicationForm = new GraduateForm(this);
                break;
            case MembershipKind.professional:
                this.applicationForm = new ProfessionalForm(this);
                break;
            case MembershipKind.associate:
                this.applicationForm = new AssociateForm(this);
                break;
            default:
                break;
        }
        this.userAddresses = new UserAddresses(
            this.parent,
            true,
            true,
            this.applicationForm?.onSelectAddress,
            true
        );
        await this.userAddresses.init();
        this.userAddresses.onSelectAddress(this.parent.initApplication?.privatePartyId ?? 0);
        window.scrollTo({ top: 0 });
    };

    @computed get subscriptionType(): MembershipKind | undefined {
        return this.parent?.subscriptionStep.subscription?.membershipKind;
    }

    @computed get isNextDisable(): boolean {
        return !this.applicationForm
            ? true
            : this.applicationForm.isNextDisable;
    }

    @computed get membershipAppId(): number {
        return !this.parent.initApplication
            ? 0
            : this.parent.initApplication.id;
    }

    @computed get application(): Partial<MembershipApplicationDto> {
        return !this.applicationForm ? {} : this.applicationForm?.formValues;
    }

    onNext = async () => {
        if (
            !!this.parent.initApplication &&
            !!this.applicationForm &&
            (await this.applicationForm.onNext())
        ) {
            this.parent.initApplication = {
                ...this.parent.initApplication,
                ...this.application
            };
            await this.parent.paymentStep.onActivation();
            return true;
        }
        return false;
    };

    public attachmentOrLinkShouldBeProvided = <T>(
        linkFormField: UrlField,
        attachments: FormField<MembershipApplicationAttachmentDto[]>,
        showErrorMessage: boolean
    ): ((_: T) => true | string[]) => {
        return (_: T) => {
            const v1 =
                (linkFormField?.value &&
                    linkFormField.value.length > 0 &&
                    RequiredFieldValidator(linkFormField.value[0].value)) ===
                true;
            const v2 = RequiredFieldValidator(attachments.value) === true;

            if (v1 || v2) {
                for (let link of linkFormField.links) {
                    for (let val of link.value.getFields()) {
                        const x = val as FormField<
                            MembershipApplicationLinkDto
                        >;
                        x.reset(x.value);
                    }
                }

                attachments.reset(attachments.value);

                return true;
            }

            return showErrorMessage
                ? [i18next.t('form:oneOfManyRequired')]
                : [''];
        };
    };
}

export default FormStep;
