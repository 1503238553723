
export enum CompetitionStatus {
    draft = "draft",
    published = "published",
    submissionOpen = "submissionOpen",
    submissionClosed = "submissionClosed",
    phaseOneJudgingInProgress = "phaseOneJudgingInProgress",
    phaseOneJudgingClosed = "phaseOneJudgingClosed",
    phaseTwoJudgingInProgress = "phaseTwoJudgingInProgress",
    phaseTwoJudgingClosed = "phaseTwoJudgingClosed",
    closed = "closed",
    archived = "archived",
}
export enum JudgingSchema {
    individual = "individual",
    group = "group",
    individualPlusGroup = "individualPlusGroup",
}
export enum QuestionType {
    singleLine = "singleLine",
    multiLine = "multiLine",
    file = "file",
    date = "date",
    number = "number",
    url = "url",
    multiFile = "multiFile",
    checkbox = "checkbox",
    singleSelect = "singleSelect",
    multiSelect = "multiSelect",
    roleInProject = "roleInProject",
    videoLink = "videoLink",
}
export enum CompetitionEntryStatus {
    created = "created",
    draft = "draft",
    submitted = "submitted",
    invalidated = "invalidated",
    deleted = "deleted",
}
export enum PaymentStatus {
    pending = "pending",
    paid = "paid",
    refund = "refund",
    zeroAuth = "zeroAuth",
    failed = "failed",
    canceled = "canceled",
    manuallyConfirmed = "manuallyConfirmed",
}
export enum CategoryEvaluationStatus {
    pending = "pending",
    fulfilled = "fulfilled",
}
export enum PhaseOneEvaluation {
    excluded = "excluded",
    zero = "zero",
    one = "one",
    two = "two",
    four = "four",
}
export enum Gender {
    male = "male",
    female = "female",
    other = "other",
}
export enum MembershipKind {
    professional = "professional",
    student = "student",
    associate = "associate",
    graduate = "graduate",
}
export enum SubscriptionType {
    monthly = "monthly",
    annual = "annual",
}
export enum PaymentMethod {
    bambora = "bambora",
    invoice = "invoice",
    free = "free",
}
export enum MembershipApplicationRequirement {
    bachelorsDegree = "bachelorsDegree",
    schoolEducation = "schoolEducation",
    workExperience = "workExperience",
    professionallyActive = "professionallyActive",
}
export enum MembershipApplicationStatus {
    pending = "pending",
    approved = "approved",
    rejected = "rejected",
}
export enum MemberStatus {
    active = "active",
    inactive = "inactive",
}
export enum PaymentResolution {
    success = "success",
    failure = "failure",
}
