import FormStep, {
    MembershipWizardForm
} from 'web/screen/MembershipApplication/FormStep/FormStep';
import { computed, observable } from 'mobx';
import {
    Form,
    FormField,
    NullValidator,
    RequiredFieldValidator
} from 'react-mvvm/forms';
import UrlField from 'common/components/antHelpers/UrlField/UrlFied';
import BirthDatePicker from 'common/components/BirthDatePicker/BirthDatePicker';
import { MembershipApplicationDto } from 'model/Api/Members/Model/MembershipApplicationDto';
import i18next from 'i18next';
import { MembershipApplicationLinkDto } from '../../../../../model/Api/Members/Model/MembershipApplicationLinkDto';
import { MembershipApplicationAttachmentDto } from '../../../../../model/Api/Members/Model/MembershipApplicationAttachmentDto';

class ProfessionalForm implements MembershipWizardForm {
    parent: FormStep;
    @observable form: Form<any>;
    @observable formValues: Partial<MembershipApplicationDto> = {};

    constructor(parent: FormStep) {
        this.parent = parent;
        this.form = this.buildMembershipForm();
    }

    @computed get userAddresses() {
        return this.parent.userAddresses;
    }

    @computed get application() {
        return this.parent.application;
    }

    buildMembershipForm = () => {
        const linkFormField = new UrlField(
            this.parent.parent.initApplication?.membershipApplicationLinks,
            NullValidator
        );

        const attachmentField = new FormField(
            this.parent.parent.initApplication?.attachments,
            NullValidator
        );

        for (let link of linkFormField.links) {
            for (let val of link.value.getFields()) {
                const x = val as FormField<MembershipApplicationLinkDto>;
                x.validator = this.parent.attachmentOrLinkShouldBeProvided(
                    linkFormField,
                    attachmentField,
                    true
                );
            }
        }

        attachmentField.validator = this.parent.attachmentOrLinkShouldBeProvided(
            linkFormField,
            attachmentField,
            false
        );

        return new Form({
            birthDate: new BirthDatePicker(
                this.parent.parent.initApplication?.birthDate,
                RequiredFieldValidator
            ),
            gender: new FormField(
                this.parent.parent.initApplication?.gender,
                RequiredFieldValidator
            ),
            privatePartyId: new FormField(
                this.parent.parent.initApplication?.privatePartyId,
                RequiredFieldValidator
            ),
            employerPartyId: new FormField(
                this.parent.parent.initApplication?.employerPartyId,
                NullValidator
            ),
            workPosition: new FormField(
                this.parent.parent.initApplication?.workPosition,
                RequiredFieldValidator
            ),
            membershipApplicationProfessionalGroups: new FormField(
                this.parent.parent.initApplication?.membershipApplicationProfessionalGroups,
                NullValidator
            ),
            membershipApplicationLinks: linkFormField,
            attachments: attachmentField,
            acceptPrivacyPolicy: new FormField(
                this.application.acceptPrivacyPolicy,
                RequiredFieldValidator
            ),
            membershipApplicationRequirement: new FormField(
                this.application.membershipApplicationRequirement,
                RequiredFieldValidator
            )
        });
    };

    onSaveForm = async (): Promise<boolean> => {
        const validation = await this.form.validate();
        if (validation) {
            const { fields } = this.form;
            this.formValues = {
                ...this.formValues,
                birthDate: fields.birthDate.value,
                gender: fields.gender.value,
                privatePartyId: fields.privatePartyId.value,
                employerPartyId: fields.employerPartyId.value,
                workPosition: fields.workPosition.value,
                membershipApplicationRequirement: fields.membershipApplicationRequirement.value,
                membershipApplicationProfessionalGroups:
                    fields.membershipApplicationProfessionalGroups.value,
                membershipApplicationLinks:
                    fields.membershipApplicationLinks.value,
                attachments: fields.attachments.value,
                acceptPrivacyPolicy: fields.acceptPrivacyPolicy.value
            };
            return true;
        }

        return false;
    };

    onNext = async () => {
        return this.onSaveForm();
    };

    @computed get isNextDisable() {
        return (
            this.form.isPristine &&
            !this.userAddresses?.doubleSelectedParty.private &&
            !this.userAddresses?.doubleSelectedParty.company
        );
    }

    onSelectAddress = async () => {
        if (!!this.userAddresses) {
            const { doubleSelectedParty } = this.userAddresses;
            this.form.fields.privatePartyId.value = doubleSelectedParty.private;
            this.form.fields.employerPartyId.value =
                doubleSelectedParty.company;
        }
    };
}

export default ProfessionalForm;
