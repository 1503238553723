import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {SubscriptionType} from "../../Externals"
import {PaymentMethod} from "../../Externals"


export interface SubmitChangeApplicationRequest {
    membershipApplicationId: number
    membershipTypeId: number
    subscriptionType: SubscriptionType
    paymentMethod: PaymentMethod
    partyId: number
    paymentTermsAccepted: boolean
}
export const submitChangeApplication = (submitChangeApplicationRequest: SubmitChangeApplicationRequest): Promise<any> => {
    return send("SubmitChangeApplication", submitChangeApplicationRequest)
}
