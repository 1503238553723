import { Page } from 'react-mvvm';
import Membership from 'web/screen/Membership/Membership';
import { action, computed, observable } from 'mobx';
import SubscriptionStep from './SubscriptionStep/SubscriptionStep';
import PaymentStep from './PaymentStep/PaymentStep';
import FormStep from 'web/screen/MembershipApplication/FormStep/FormStep';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import loader from 'react-mvvm/loading/loader';
import { createOrUpdateMembershipApplication } from 'model/Api/Members/CreateOrUpdateMembershipApplicationRequest';
import { MembershipApplicationDto } from 'model/Api/Members/Model/MembershipApplicationDto';
import { PaymentMethod } from 'model/Externals';
import { getUserInfo } from 'model/Api/Individuals/GetUserInfoRequest';
import { UserInfoDto } from 'model/Api/Individuals/Model/UserInfoDto';
import AddressDialog from '../../../common/components/AddressDialog/AddressDialog';

export interface MembershipWizardStep {
    onNext: () => Promise<boolean>;
    isNextDisable: boolean;
}

class MembershipApplication extends Page<AddressDialog | ConfirmationModal> {
    @observable parent: Membership;
    @observable initApplication: MembershipApplicationDto | undefined;
    @observable userInfo: UserInfoDto | undefined;
    //@observable membershipInfo: MemberDto | undefined;

    @observable subscriptionStep: SubscriptionStep;
    @observable formStep: FormStep;
    @observable paymentStep: PaymentStep;
    @observable step: number = 0;

    constructor(parent: Membership) {
        super();
        this.parent = parent;
        this.subscriptionStep = new SubscriptionStep(this);
        this.formStep = new FormStep(this);
        this.paymentStep = new PaymentStep(this);
    }

    api = loader({
        createOrUpdateMembershipApplication: async () =>
            createOrUpdateMembershipApplication({}),
        getUserInfo: async () => getUserInfo({})
    });

    protected async onActivated(): Promise<any> {
        this.userInfo = await this.api.getUserInfo();
        // if (
        //     !!this.initApplication &&
        //     this.initApplication.membershipApplicationStatus ===
        //         MembershipApplicationStatus.approved
        // ) {
        //     await this.parent.removeChildPage();
        //     await this.parent.activate();
        //     return;
        // }

        this.initApplication = await this.api.createOrUpdateMembershipApplication();
        await this.subscriptionStep.init();
    }

    @action
    nextStep = () => (this.step = this.step + 1);

    @action
    onPrevStep = () => (this.step = this.step - 1);

    @action
    onBackButtonEvent = () => {
      if (this.isPrevDisable) {
        window.history.back();
      } else {
        this.onPrevStep();
      }
    }

    onNextStep = async () => {
        window.history.pushState(null, "", window.location.pathname);
        let valid = false;
        switch (this.step) {
            case 0:
                valid = await this.subscriptionStep.onNext();
                break;
            case 1:
                valid = await this.formStep.onNext();
                break;
            default:
                return;
        }

        if (valid) await this.nextStep();
    };

    @computed get isPrevDisable() {
        return !this.step;
    }

    @computed get isNextDisable() {
        switch (this.step) {
            case 0:
                return this.subscriptionStep.isNextDisable;
            case 1:
                return this.formStep.isNextDisable;
            default:
                return true;
        }
    }

    @computed get userName(): string | undefined {
        if (!this.userInfo) {
            return '-';
        }
        return `${this.userInfo.family} ${this.userInfo.given}`;
    }

    @computed get isPaymentDisable() {
        return (
            !this.paymentStep.subscriptionType ||
            !this.paymentStep.paymentMethod ||
            !this.paymentStep.userAddresses.selectedParty ||
            !this.paymentStep.isPaymentTermsAccepted
        );
    }

    onPay = async () => {
        if (this.parent.isActiveRegularMember) {
            await this.paymentStep.onChange();
        } else if (this.paymentStep.paymentMethod === PaymentMethod.bambora) {
            await this.paymentStep.onPayNow();
        } else {
            await this.paymentStep.onPayByInvoice();
        }
    };
}

export default MembershipApplication;
