import * as React from 'react';
import style from './UserAddresses.module.scss';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown } from 'antd';
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import MenuDropdown from 'common/components/UserAddresses/MenuDropdown';
import SingleUserAddressesDialogsView from './SingleUserAddressesDialogsView';
import { observer } from 'mobx-react';

interface AllUsersAddressDropdownProps {
    model: UserAddresses | undefined;
    withCreateAddress?: boolean;
    userId?: number;
    disabled?: boolean;
}

const AllUsersAddressDropdown: React.FC<AllUsersAddressDropdownProps> = observer(
    ({ model, userId, withCreateAddress = true, disabled }) => {
        const { t } = useTranslation(['userAddresses', 'common']);
        const [visible, setVisible] = React.useState<boolean>(false);
        if (!model || (!!model && !model.isSelectable)) {
            return null;
        }
        return (
            <div>
                <Dropdown
                    visible={visible}
                    trigger={['click']}
                    onVisibleChange={(e) => setVisible(e)}
                    disabled={disabled}
                    overlay={
                        <MenuDropdown
                            userId={userId}
                            data={[
                                ...model.sortedParties['private'],
                                ...model.sortedParties['company']
                            ]}
                            model={model}
                            setVisible={setVisible}
                            selectedId={
                                !!model.selectedParty
                                    ? model.selectedParty
                                    : undefined
                            }
                        />
                    }
                    className={style.dropdown}>
                    <Button type={'text'} className={style.truncateBtn}>
                        <span className={style.partyTruncate}>
                            {!model.selectedParty &&
                            !model.selectedPartyData ? (
                                t('userAddresses:select')
                            ) : (
                                <>
                                    {!!model.selectedPartyData?.name &&
                                        `${model.selectedPartyData.name}, `}
                                    {!!model.selectedPartyData?.careOf &&
                                        `${
                                            !!model.selectedPartyData?.orgNo
                                                ? 'att. '
                                                : 'c/o '
                                        } ${model.selectedPartyData?.careOf}, `}
                                    {!!model.selectedPartyData?.address.line &&
                                        `${model.selectedPartyData.address.line}, `}
                                    {`${model.selectedPartyData?.address.postalCode} ${model.selectedPartyData?.address.city}`}
                                </>
                            )}
                        </span>
                        <DownOutlined />
                    </Button>
                </Dropdown>
                {withCreateAddress && !disabled && (
                    <>
                        <Button
                            type={'text'}
                            size={'small'}
                            onClick={() =>
                                model.onCreateAddress(undefined, userId)
                            }>
                            <PlusOutlined /> {t('userAddresses:addAddress')}
                        </Button>
                        <SingleUserAddressesDialogsView model={model} />
                    </>
                )}
            </div>
        );
    }
);

export default AllUsersAddressDropdown;
